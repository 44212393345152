import React from "react";

import { IconProps } from "./types";

export const KeyIcon: React.FC<IconProps> = ({ size = 32 }) => (
  <svg width={size} height={size} viewBox="0 0 32 32" fill="none">
    <path
      d="M19.3333 20C23.3833 20 26.6666 16.7167 26.6666 12.6667C26.6666 8.61671 23.3833 5.33337 19.3333 5.33337C15.2833 5.33337 11.9999 8.61671 11.9999 12.6667C11.9999 13.4459 12.1208 14.2 12.3458 14.9042L5.62492 21.625C5.43742 21.8125 5.33325 22.0667 5.33325 22.3334V25.6667C5.33325 26.2209 5.77909 26.6667 6.33325 26.6667H9.66659C10.2208 26.6667 10.6666 26.2209 10.6666 25.6667V24H12.3333C12.8874 24 13.3333 23.5542 13.3333 23V21.3334H14.9999C15.2666 21.3334 15.5208 21.2292 15.7083 21.0417L17.0958 19.6542C17.7999 19.8792 18.5541 20 19.3333 20ZM20.9999 9.33337C21.4419 9.33337 21.8659 9.50897 22.1784 9.82153C22.491 10.1341 22.6666 10.558 22.6666 11C22.6666 11.4421 22.491 11.866 22.1784 12.1786C21.8659 12.4911 21.4419 12.6667 20.9999 12.6667C20.5579 12.6667 20.134 12.4911 19.8214 12.1786C19.5088 11.866 19.3333 11.4421 19.3333 11C19.3333 10.558 19.5088 10.1341 19.8214 9.82153C20.134 9.50897 20.5579 9.33337 20.9999 9.33337Z"
      fill="currentColor"
    />
  </svg>
);
