import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import { ROUTES } from "constants/routes";
import { AuthLayout, RootLayout } from "layouts";
import { Home, Sessions, Login, Profile } from "pages";
import { AuthProvider } from "provider";
import { Toast } from "components/Toast";
import "locales/i18n";

export const App: React.FC = () => (
  <AuthProvider>
    <BrowserRouter>
      <Routes>
        {/* auth routes */}
        <Route element={<AuthLayout />}>
          <Route path={ROUTES.LOGIN} Component={Login} />
          <Route path="*" element={<Navigate to={ROUTES.LOGIN} />} />
        </Route>

        {/* root routes */}
        <Route element={<RootLayout />}>
          <Route path={ROUTES.HOME} Component={Home} />
          <Route path={ROUTES.SESSIONS} Component={Sessions} />
          <Route path={ROUTES.PROFILE} Component={Profile} />
          <Route path="*" element={<Navigate to={ROUTES.HOME} />} />
        </Route>
      </Routes>
    </BrowserRouter>

    <Toast />
  </AuthProvider>
);
