import React from "react";

import { IconProps } from "./types";

export const EditIcon: React.FC<IconProps> = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      d="M20.1421 3.85757C18.9987 2.71414 17.1448 2.71414 16.0014 3.85757L6.39371 13.4653L10.5344 17.606L20.1421 7.99831C21.2856 6.85488 21.2856 5.00101 20.1421 3.85757Z"
      fill="currentColor"
    />
    <path
      d="M8.96303 19.1774L4.82229 15.0367L4.48116 15.3778C4.33907 15.5199 4.23817 15.6979 4.1892 15.8928L3.01035 20.5851C2.94895 20.8295 3.1705 21.0511 3.4149 20.9896L8.10698 19.8105C8.30185 19.7615 8.47979 19.6606 8.62187 19.5186L8.96303 19.1774Z"
      fill="currentColor"
    />
  </svg>
);
