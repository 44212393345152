import React from "react";

import { IconProps } from "icons/types";
import { getCurrentScreenData } from "utils/getCurrentScreenData";

interface FrameProps {
  title: string;
  children: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  HeaderIcon?: React.FC<IconProps>;
}

const iconSize = getCurrentScreenData({ sm: 24, all: 32 });

export const Frame: React.FC<FrameProps> = ({
  title,
  children,
  header,
  footer,
  HeaderIcon,
}) => (
  <div className="mt-4 bg-gray1 rounded-lg flex flex-col flex-1 min-w-[500px] sm:min-w-full">
    <div className="px-8 md:px-6 sm:px-4 h-[80px] md:h-[72px] sm:h-[64px] flex items-center justify-between border-b border-gray3">
      <div className="text-white flex items-center overflow-hidden">
        {HeaderIcon && <HeaderIcon size={iconSize} />}

        <span
          className={`font-bold whitespace-nowrap text-xl md:text-lg sm:text-base ${
            HeaderIcon ? "ml-4" : ""
          }`}
        >
          {title}
        </span>
      </div>

      {header}
    </div>

    <div className="px-8 md:px-6 sm:px-4">{children}</div>

    {footer ? (
      <div className="px-8 md:px-6 sm:px-4 h-[80px] md:h-[72px] sm:h-[64px] flex items-center justify-end border-t border-gray3">
        {footer}
      </div>
    ) : null}
  </div>
);
