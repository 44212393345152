import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { serverApi } from "api";
import { SER } from "utils/serverErrorHandler";
import { Frame } from "components/Frame";
// import { Checkbox } from "components/Checkbox";
import { Button } from "components/Button";
import { Input } from "components/Input";
import { Loading } from "components/Loading";
// import { InfoIcon } from "icons/InfoIcon";
import { KeyIcon } from "icons/KeyIcon";
import { EditIcon } from "icons/EditIcon";

export const Security: React.FC = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState<Record<string, string>>({});
  const [errors, setErrors] = useState<Record<string, string>>({});
  // const [isTwoFactorAuth, setIsTwoFactorAuth] = useState(false);

  const { t } = useTranslation();

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    setValues({});
    setErrors({});
  }, [isEditMode]);

  const onSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();

      if (Object.values(errors).every((item) => !item?.length)) {
        SER(
          async () => {
            setIsLoading(true);

            await serverApi.updateUser(values);

            setIsEditMode(false);
            toast.success(t("user_success_message"));
          },
          undefined,
          () => {
            setIsLoading(false);
          }
        );
      }
    },
    [t, errors, values]
  );

  const onBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      const newErrors = { ...errors };

      switch (name) {
        case "secret":
        case "new_secret":
          if (value.length >= 1 && value.length < 8) {
            newErrors[name] = t("password_warning");
          } else {
            delete newErrors[name];
          }
          break;
      }

      setErrors(newErrors);
    },
    [t, errors]
  );

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setValues({ ...values, [name]: value });
    },
    [values]
  );

  const saveButtonDisabled = useMemo(
    () =>
      !(
        Object.keys(values).length === 2 &&
        Object.values(values).every((item) => item.length) &&
        Object.values(errors).every((item) => !item) &&
        !isLoading
      ),
    [values, errors, isLoading]
  );

  // const toggleTwoFactorAuth = useCallback(() => {
  //   setIsTwoFactorAuth(!isTwoFactorAuth);
  // }, [isTwoFactorAuth]);

  const enableEditMode = useCallback(() => {
    setIsEditMode(true);
  }, []);

  const disableEditMode = useCallback(() => {
    setIsEditMode(false);
  }, []);

  const disabledFilds = useMemo(
    () => !(isEditMode && !isLoading),
    [isLoading, isEditMode]
  );

  return (
    <Frame
      title={t("sign_in")}
      HeaderIcon={KeyIcon}
      header={
        isEditMode ? (
          <div className="flex">
            <Button
              type="transparent"
              disabled={isLoading}
              onClick={disableEditMode}
            >
              {t("cancel")}
            </Button>
            <Button
              disabled={saveButtonDisabled}
              IconBefore={isLoading ? Loading : undefined}
              onClick={() => formRef.current?.requestSubmit()}
            >
              {t("save")}
            </Button>
          </div>
        ) : (
          <Button
            type="transparentBorder"
            IconBefore={EditIcon}
            onClick={enableEditMode}
          >
            {t("edit")}
          </Button>
        )
      }
    >
      <form
        key={String(isEditMode)}
        ref={formRef}
        onSubmit={onSubmit}
        className="pt-2 pb-6"
      >
        <Input
          name="secret"
          type="password"
          label={t("password")}
          defaultValue={isEditMode ? "" : "••••••••••"}
          disabled={disabledFilds}
          values={values}
          errors={errors}
          onBlur={onBlur}
          onChange={onChange}
        />
        <Input
          name="new_secret"
          type="password"
          label={t("new_password")}
          defaultValue={isEditMode ? "" : "••••••••••"}
          disabled={disabledFilds}
          values={values}
          errors={errors}
          onBlur={onBlur}
          onChange={onChange}
        />
      </form>

      {/* <div className="h-[80px] md:h-[72px] flex items-center justify-between border-t border-gray3">
        <div className="flex items-center">
          <span className="text-white text-base font-semibold">
            {t("two_factor")}
          </span>

          <div className="ml-6 text-[#F2C94D] flex items-center">
            <InfoIcon size={16} />

            <span className="ml-2 text-sm font-normal">
              {t("strongly_recommended")}
            </span>
          </div>
        </div>

        <Checkbox isActive={isTwoFactorAuth} onClick={toggleTwoFactorAuth} />
      </div> */}
    </Frame>
  );
};
