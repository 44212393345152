import React, { useEffect, useRef } from "react";

import { CloseIcon } from "icons/CloseIcon";
import { IconProps } from "icons/types";

interface ModalProps {
  title: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  Icon?: React.FC<IconProps>;
  hideModal?: () => void;
}

export const Modal: React.FC<ModalProps> = ({
  title,
  children,
  Icon,
  footer,
  hideModal,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modalRef.current) {
      (
        modalRef.current.querySelector(
          "form input:not([type=file]):not([disabled])"
        ) as HTMLElement
      )?.focus();
    }
  }, []);

  return (
    <div
      ref={modalRef}
      className="fixed w-full h-full top-0 left-0, right-0 bottom-0 flex items-center justify-center z-10 bg-[rgba(0,0,0,.5)]"
    >
      <div className="bg-gray1 rounded-lg min-w-[600px] max-h-[90%] flex flex-col z-20 md:min-w-full md:min-h-full md:rounded-none">
        <div className="px-8 min-h-[72px] flex items-center justify-between border-b border-gray3 text-white sm:px-6 sm:min-h-[64px]">
          <div className="flex items-center">
            {Icon ? (
              <div className="mr-4">
                <Icon size={24} />
              </div>
            ) : null}
            <span className="font-bold text-xl sm:text-lg">{title}</span>
          </div>

          {hideModal ? (
            <div className="cursor-pointer" onClick={hideModal}>
              <CloseIcon />
            </div>
          ) : null}
        </div>

        <div className="overflow-y-scroll">{children}</div>

        {footer ? (
          <div className="px-8 min-h-[72px] flex items-center border-t border-gray3 sm:px-6 sm:min-h-[64px]">
            {footer}
          </div>
        ) : null}
      </div>
    </div>
  );
};
