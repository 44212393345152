import React from "react";

import { IconProps } from "./types";

export const DownloadIcon: React.FC<IconProps> = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 24 25" fill="none">
    <path
      d="M13 5C13 4.44687 12.5531 4 12 4C11.4469 4 11 4.44687 11 5V12.5844L8.70625 10.2906C8.31563 9.9 7.68125 9.9 7.29063 10.2906C6.9 10.6813 6.9 11.3156 7.29063 11.7063L11.2906 15.7063C11.6813 16.0969 12.3156 16.0969 12.7063 15.7063L16.7063 11.7063C17.0969 11.3156 17.0969 10.6813 16.7063 10.2906C16.3156 9.9 15.6812 9.9 15.2906 10.2906L13 12.5844V5ZM6 15C4.89688 15 4 15.8969 4 17V18C4 19.1031 4.89688 20 6 20H18C19.1031 20 20 19.1031 20 18V17C20 15.8969 19.1031 15 18 15H14.8281L13.4125 16.4156C12.6313 17.1969 11.3656 17.1969 10.5844 16.4156L9.17188 15H6ZM17.5 16.75C17.6989 16.75 17.8897 16.829 18.0303 16.9697C18.171 17.1103 18.25 17.3011 18.25 17.5C18.25 17.6989 18.171 17.8897 18.0303 18.0303C17.8897 18.171 17.6989 18.25 17.5 18.25C17.3011 18.25 17.1103 18.171 16.9697 18.0303C16.829 17.8897 16.75 17.6989 16.75 17.5C16.75 17.3011 16.829 17.1103 16.9697 16.9697C17.1103 16.829 17.3011 16.75 17.5 16.75Z"
      fill="currentColor"
    />
  </svg>
);
