import React, { useMemo } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useAuthProvider } from "provider";
import { ROUTES } from "constants/routes";

export const AuthLayout: React.FC = () => {
  const { user } = useAuthProvider();

  return useMemo(() => {
    if (user === undefined) return null;
    else if (user) return <Navigate replace to={ROUTES.HOME} />;

    return <Outlet />;
  }, [user]);
};
