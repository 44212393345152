import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { formatDate } from "utils/formatDate";
import { SER } from "utils/serverErrorHandler";
import { Header } from "components/Header";
import { Button } from "components/Button";
import { ExitIcon } from "icons/ExitIcon";
import { ChevronLeftIcon } from "icons/ChevronLeftIcon";
import { UserSession } from "types";

export const Sessions: React.FC = () => {
  const [userSession, setUserSession] = useState<UserSession[]>([]);
  const [activeSessionId, setActiveSessionId] = useState<string | null>(null);

  const { t } = useTranslation();

  const getUserSession = useCallback(() => {
    SER(async () => {
      const { data: session } = await serverApi.getUserSession();
      setUserSession(session.data);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUserSession();
  }, [getUserSession]);

  const toggleActiveSession = useCallback(
    (sessionId: string) => {
      setActiveSessionId(sessionId === activeSessionId ? null : sessionId);
    },
    [activeSessionId]
  );

  const destroyUserSession = useCallback(
    (sessionId: string) => {
      SER(async () => {
        await serverApi.destroyUserSession(sessionId);
        getUserSession();
      });
    },
    [getUserSession]
  );

  return (
    <>
      <Header title={t("sessions")} />

      <section className="px-8 py-6 md:px-6 md:py-4 sm:px-4 flex flex-col">
        {userSession.map((session, i) => {
          const isActive = activeSessionId === session.uid;

          return (
            <div
              key={i}
              className={`bg-gray1 flex flex-col first:rounded-t-lg last:rounded-b-lg overflow-hidden border-gray3 ${
                isActive ? "" : "[&:not(:last-child)]:border-b"
              }`}
            >
              <div className="px-8 md:px-6 sm:px-4 h-[80px] md:h-[72px] flex items-center">
                <div className="flex flex-1 items-center">
                  <div
                    className={`w-[40px] aspect-square rounded-lg flex justify-center items-center ${
                      session.currentSession ? "bg-green" : "bg-red"
                    }`}
                  >
                    <span className="text-[32px] font-thin text-white leading-6">
                      {t("session")[0]}
                    </span>
                  </div>

                  <span className="ml-4 font-bold text-white text-xl md:text-lg sm:hidden">
                    {t("session")}
                  </span>

                  <span className="ml-4 text-gray6 font-normal text-base">
                    ({formatDate.toLocaleDateString(session.last_used)})
                  </span>
                </div>

                <div className="flex items-center justify-end">
                  <Button
                    small
                    type="danger"
                    IconBefore={ExitIcon}
                    onClick={() => destroyUserSession(session.uid)}
                  >
                    {t("logout")}
                  </Button>

                  <div
                    className="ml-4 text-white cursor-pointer"
                    style={{ rotate: isActive ? "90deg" : "270deg" }}
                    onClick={() => toggleActiveSession(session.uid)}
                  >
                    <ChevronLeftIcon />
                  </div>
                </div>
              </div>

              {isActive
                ? session.devices.map((device) => (
                    <div
                      key={device.id}
                      className="px-8 md:px-6 sm:px-4 h-[80px] md:h-[72px] bg-gray2 flex items-center border-gray3 [&:not(:last-child)]:border-b justify-between"
                    >
                      <span className="flex flex-1 text-white font-normal text-base sm:text-sm whitespace-nowrap overflow-hidden sm:mr-3">
                        <span className="mr-4 sm:mr-3 text-gray6">
                          [{device.country}]
                        </span>

                        {device.device}
                      </span>

                      <span className="text-gray6 font-normal text-base sm:text-sm">
                        {formatDate.toLocaleDateString(device.last_used)}
                      </span>
                    </div>
                  ))
                : null}
            </div>
          );
        })}
      </section>
    </>
  );
};
