import { version } from "./version";

export const URL = {
  API: `${process.env.REACT_APP_API_URL}`,
  AUTH: `${process.env.REACT_APP_AUTH_URL}`,
  CALLBACK: `${process.env.REACT_APP_URL}/login`,
  LOGOUT: `${process.env.REACT_APP_URL}/logout`,
};

export const TOKEN = {
  CLIENT_SECRET: `${process.env.REACT_APP_CLIENT_SECRET}`,
  CLIENT_ID: `${process.env.REACT_APP_CLIENT_ID}`,
};

export const VERSION = version;
