import React, { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ROUTES } from "constants/routes";
import { SettingsIcon } from "icons/SettingsIcon";
import { ChevronLeftIcon } from "icons/ChevronLeftIcon";
import { HomeIcon } from "icons/HomeIcon";
import { SessionsIcon } from "icons/SessionsIcon";

export const NavBar: React.FC = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const toggleCollapse = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);

  const nav = useCallback(
    (route: ROUTES) => () => {
      navigate(route);
    },
    [navigate]
  );

  const renderButton = useCallback(
    (text: string, Icon: React.FC, route: ROUTES) => {
      const isActive = pathname.includes(route.replace(/\/:\w+/, ""));

      return (
        <button
          className={`flex flex-row w-full sm:w-[64px] h-[64px] items-center transition-all duration-200 ease-in-out hover:text-white hover:bg-black ${
            isActive ? "bg-black text-white" : "text-gray6"
          }`}
          onClick={nav(route)}
        >
          <div className="min-w-[64px] flex justify-center">
            <Icon />
          </div>
          <span className="text-sm font-bold whitespace-nowrap overflow-hidden">
            {text}
          </span>
        </button>
      );
    },
    [pathname, nav]
  );

  return (
    <nav
      className="group flex flex-col justify-between bg-gray1 relative transition-all duration-200 ease-in-out xl:!w-[64px] xl:!min-w-[64px] sm:flex-row sm:!w-full sm:!min-w-full"
      style={{
        width: isCollapsed ? "64px" : "232px",
        minWidth: isCollapsed ? "64px" : "232px",
      }}
    >
      <div className="sm:flex">
        <div className="h-[64px] mb-[64px] relative sm:w-[64px] sm:mb-0">
          <div
            className="px-5 w-[232px] h-full flex items-center absolute top-0 transition-all duration-200 ease-in-out xl:!left-[-232px]"
            style={{ left: isCollapsed ? "-232px" : "0" }}
          >
            <img
              src={require("../assets/Logo-Expanded.png")}
              alt=""
              className="w-full"
            />
          </div>

          <img
            src={require("../assets/Logo-Collapsed.png")}
            alt=""
            className="h-full aspect-square absolute top-0 transition-all duration-200 ease-in-out xl:!left-0"
            style={{ left: isCollapsed ? "0" : "-64px" }}
          />
        </div>

        {renderButton(t("home"), HomeIcon, ROUTES.HOME)}
        {renderButton(t("sessions"), SessionsIcon, ROUTES.SESSIONS)}
      </div>

      {renderButton(t("profile"), SettingsIcon, ROUTES.PROFILE)}

      <div
        className="w-[32px] h-[72px] rounded-lg rounded-l-none absolute bg-gray1 top-0 bottom-0 right-[-32px] mt-auto mb-auto text-gray6 flex items-center justify-center cursor-pointer invisible opacity-0 group-hover:visible group-hover:opacity-100 transition-all duration-200 ease-in-out xl:hidden"
        onClick={toggleCollapse}
      >
        <div style={{ rotate: isCollapsed ? "180deg" : "0deg" }}>
          <ChevronLeftIcon size={16} />
        </div>
      </div>
    </nav>
  );
};
