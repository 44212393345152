import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { useAuthProvider } from "provider";
import { SER } from "utils/serverErrorHandler";
import { Checkbox } from "components/Checkbox";
import { Frame } from "components/Frame";
import { Button } from "components/Button";
import { ModalChangeLanguage } from "components/Modals/ModalChangeLanguage";
import { MegaphoneIcon } from "icons/MegaphoneIcon";
import { LanguageIcon } from "icons/LanguageIcon";
import { UserLanguage, UserNotification } from "types";

export const Preferences: React.FC = () => {
  const [isModalChangeLanguage, setIsModalChangeLanguage] = useState(false);
  const [userLanguages, setUserLanguages] = useState<UserLanguage[]>([]);
  const [userNotification, setUserNotification] = useState<UserNotification[]>(
    []
  );

  const { t } = useTranslation();

  const { user } = useAuthProvider();

  const getUserData = useCallback(() => {
    SER(async () => {
      const [{ data: languages }, { data: notification }] = await Promise.all([
        serverApi.getUserLanguages(),
        serverApi.getUserNotification(),
      ]);
      setUserLanguages(languages.data);
      setUserNotification(notification.data);
    });
  }, []);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  const showModalChangeLanguage = useCallback(() => {
    setIsModalChangeLanguage(true);
  }, []);

  const hideModalChangeLanguage = useCallback(() => {
    setIsModalChangeLanguage(false);
  }, []);

  const selectedLanguage = useMemo(
    () => userLanguages.find((language) => language.lang === user?.lang)?.name,
    [userLanguages, user]
  );

  const toggleNotification = useCallback((notification: UserNotification) => {
    SER(async () => {
      const { data: languages } = await serverApi.setUserNotification(
        notification.id,
        notification.enabled === 1 ? 0 : 1
      );
      setUserNotification(languages.data);
    });
  }, []);

  return (
    <>
      <Frame title={t("email_notifications")} HeaderIcon={MegaphoneIcon}>
        {userNotification.map((notification) => (
          <div
            key={notification.id}
            className="h-[80px] md:h-[72px] sm:h-[64px] flex items-center justify-between"
          >
            <span className="text-white text-base sm:text-sm font-semibold">
              {t(notification.name.replace(":", "_"))}
            </span>

            <Checkbox
              isActive={notification.enabled === 1}
              onClick={() => toggleNotification(notification)}
            />
          </div>
        ))}
      </Frame>

      <Frame title={t("language")} HeaderIcon={LanguageIcon}>
        <div className="h-[80px] md:h-[72px] flex items-center justify-between">
          <span className="text-white text-base sm:text-sm font-semibold">
            {selectedLanguage}
          </span>

          <Button type="transparentBorder" onClick={showModalChangeLanguage}>
            {t("change")}
          </Button>
        </div>
      </Frame>

      {isModalChangeLanguage && (
        <ModalChangeLanguage
          userLanguages={userLanguages}
          hideModal={hideModalChangeLanguage}
        />
      )}
    </>
  );
};
