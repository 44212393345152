import React from "react";

import { IconProps } from "./types";

export const CheckIcon: React.FC<IconProps> = ({ size = 16 }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.75 3.52277C13.0262 3.72988 13.0821 4.12163 12.875 4.39777L6.90913 12.3523C6.79605 12.503 6.62099 12.5947 6.43266 12.6018C6.24434 12.6089 6.06287 12.5307 5.93877 12.3888L3.15469 9.20704C2.92739 8.94727 2.95372 8.55242 3.21349 8.32512C3.47326 8.09782 3.86811 8.12414 4.09541 8.38391L6.37176 10.9855L11.875 3.64777C12.0821 3.37163 12.4739 3.31567 12.75 3.52277Z"
      fill="currentColor"
    />
  </svg>
);
