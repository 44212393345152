import React from "react";

import { IconProps } from "./types";

export const EyeIcon: React.FC<IconProps> = ({ size = 32 }) => (
  <svg width={size} height={size} viewBox="0 0 32 32" fill="none">
    <path
      d="M16.0001 6.66669C12.6334 6.66669 9.93756 8.20002 7.97506 10.025C6.02506 11.8334 4.72089 14 4.10422 15.4875C3.96672 15.8167 3.96672 16.1834 4.10422 16.5125C4.72089 18 6.02506 20.1667 7.97506 21.975C9.93756 23.8 12.6334 25.3334 16.0001 25.3334C19.3667 25.3334 22.0626 23.8 24.0251 21.975C25.9751 20.1625 27.2792 18 27.9001 16.5125C28.0376 16.1834 28.0376 15.8167 27.9001 15.4875C27.2792 14 25.9751 11.8334 24.0251 10.025C22.0626 8.20002 19.3667 6.66669 16.0001 6.66669ZM10.0001 16C10.0001 14.4087 10.6322 12.8826 11.7574 11.7574C12.8826 10.6322 14.4088 10 16.0001 10C17.5914 10 19.1175 10.6322 20.2427 11.7574C21.3679 12.8826 22.0001 14.4087 22.0001 16C22.0001 17.5913 21.3679 19.1174 20.2427 20.2427C19.1175 21.3679 17.5914 22 16.0001 22C14.4088 22 12.8826 21.3679 11.7574 20.2427C10.6322 19.1174 10.0001 17.5913 10.0001 16ZM16.0001 13.3334C16.0001 14.8042 14.8042 16 13.3334 16C13.0376 16 12.7542 15.95 12.4876 15.8625C12.2584 15.7875 11.9917 15.9292 12.0001 16.1709C12.0126 16.4584 12.0542 16.7459 12.1334 17.0334C12.7042 19.1667 14.9001 20.4334 17.0334 19.8625C19.1667 19.2917 20.4334 17.0959 19.8626 14.9625C19.4001 13.2334 17.8709 12.0709 16.1709 12C15.9292 11.9917 15.7876 12.2542 15.8626 12.4875C15.9501 12.7542 16.0001 13.0375 16.0001 13.3334Z"
      fill="currentColor"
    />
  </svg>
);
