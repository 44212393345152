import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { Button } from "components/Button";
import { DownloadIcon } from "icons/DownloadIcon";
import { SuccessIcon } from "icons/SuccessIcon";
import { SER } from "utils/serverErrorHandler";

export const ButtonGDPR: React.FC = () => {
  const [GDPRStatus, setGDPRStatus] = useState<boolean | null>(null);

  const { t } = useTranslation();

  const getUserGDPRExportStatus = useCallback(() => {
    serverApi
      .getUserGDPRExportStatus()
      .then(() => setGDPRStatus(true))
      .catch(() => setGDPRStatus(false));
  }, []);

  useEffect(() => {
    getUserGDPRExportStatus();
  }, [getUserGDPRExportStatus]);

  const getUserGDPRExport = useCallback(() => {
    if (!GDPRStatus) {
      SER(async () => {
        const { status } = await serverApi.getUserGDPRExport();
        setGDPRStatus(status === 200);
      });
    }
  }, [GDPRStatus]);

  return (
    <Button
      type={GDPRStatus ? "success" : "transparentBorder"}
      IconBefore={GDPRStatus ? SuccessIcon : DownloadIcon}
      onClick={getUserGDPRExport}
    >
      {t("export")}
    </Button>
  );
};
