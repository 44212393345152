import React, { useMemo, useState } from "react";

import { CheckIcon } from "icons/CheckIcon";
import { CloseIcon } from "icons/CloseIcon";
import { EyeIcon } from "icons/EyeIcon";
import { EyeSlashIcon } from "icons/EyeSlashIcon";

interface InputProps {
  name: string;
  placeholder?: string;
  label?: string;
  type?: React.HTMLInputTypeAttribute;
  defaultValue?: string | null;
  disabled?: boolean;
  required?: boolean;
  values?: Record<string, string>;
  errors?: Record<string, string>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export const Input: React.FC<InputProps> = ({
  name,
  placeholder,
  label,
  type,
  defaultValue,
  disabled,
  required = true,
  values,
  errors,
  onBlur,
  onChange,
}) => {
  const [secureTextEntry, setSecureTextEntry] = useState(true);

  const error = useMemo(() => errors?.[name], [errors, name]);
  const value = useMemo(() => values?.[name], [values, name]);
  const succeed = useMemo(
    () => Boolean(value?.length && !error),
    [value, error]
  );

  const color = useMemo(() => {
    if (succeed) return { border: "border-green", text: "text-green" };
    if (error) return { border: "border-red", text: "text-red" };

    return { border: "border-transparent", text: "" };
  }, [succeed, error]);

  const inputType = useMemo(() => {
    if (type === "password") return secureTextEntry ? "password" : "text";
    return type;
  }, [type, secureTextEntry]);

  return (
    <div className="relative">
      {label ? (
        <span className="block mt-3 mb-2 text-sm font-normal text-gray6">
          {label}
        </span>
      ) : null}
      <input
        disabled={disabled}
        required={required}
        name={name}
        type={inputType}
        autoComplete={type}
        placeholder={placeholder}
        defaultValue={defaultValue || undefined}
        className={`w-full rounded-lg h-[40px] bg-gray3 text-white text-sm font-normal px-4 placeholder-gray6 disabled:opacity-50 border-2 focus:border-white ${color.border}`}
        onBlur={onBlur}
        onChange={onChange}
      />
      {error && <span className="text-sm text-red font-normal">{error}</span>}

      {type === "password" && !disabled && (
        <div
          className={`absolute h-[40px] aspect-square flex items-center justify-center cursor-pointer ${
            succeed || error ? "right-[40px]" : "right-0"
          } top-7 text-white ${color.text}`}
          onClick={() => setSecureTextEntry(!secureTextEntry)}
        >
          {secureTextEntry ? <EyeSlashIcon size={16} /> : <EyeIcon size={16} />}
        </div>
      )}

      {(succeed || error) && (
        <div
          className={`absolute h-[40px] aspect-square flex items-center justify-center right-0 top-7 ${color.text}`}
        >
          {succeed && <CheckIcon size={16} />}
          {error && <CloseIcon size={16} />}
        </div>
      )}
    </div>
  );
};
