import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { Frame } from "components/Frame";
import { ButtonGDPR } from "components/ButtonGDPR";
import { ModalDataExport } from "components/Modals/ModalDataExport";
import { FileIcon } from "icons/FileIcon";

export const Privacy: React.FC = () => {
  const [isModalDataExport, setIsModalDataExport] = useState(false);

  const { t } = useTranslation();

  const showModalDataExport = useCallback(() => {
    setIsModalDataExport(true);
  }, []);

  const hideModalDataExport = useCallback(() => {
    setIsModalDataExport(false);
  }, []);

  return (
    <>
      <Frame
        title={t("data_export")}
        HeaderIcon={FileIcon}
        header={<ButtonGDPR />}
      >
        <div className="flex flex-col py-6 md:py-4">
          <span className="text-gray9 font-normal text-base sm:text-sm text-justify">
            We value your privacy and are committed to ensuring the transparency
            and control of your personal information. In accordance with the
            European Union's General Data Protection Regulation (GDPR), we
            empower you with the ability to access and export your personal data
            that we hold...
          </span>

          <div className="mt-6 cursor-pointer" onClick={showModalDataExport}>
            <span className="text-white text-base font-normal underline">
              {t("read_more")}...
            </span>
          </div>
        </div>
      </Frame>

      {isModalDataExport && <ModalDataExport hideModal={hideModalDataExport} />}
    </>
  );
};
