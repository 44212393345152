import React, { useState } from "react";

import { UserIcon } from "icons/UserIcon";

interface ImageProps {
  imageSize: number;
  iconSize: number;
  src: string | null | undefined;
}

export const Image: React.FC<ImageProps> = ({ imageSize, iconSize, src }) => {
  const [isError, setIsError] = useState(false);

  return src && !isError ? (
    <img
      src={src}
      alt=""
      className="aspect-square rounded-full"
      style={{ width: imageSize }}
      onError={() => setIsError(true)}
    />
  ) : (
    <div
      className="aspect-square rounded-full bg-gray3 text-gray6 flex items-center justify-center"
      style={{ width: imageSize }}
    >
      <UserIcon size={iconSize} />
    </div>
  );
};
