import React from "react";

import { IconProps } from "./types";

export const SessionsIcon: React.FC<IconProps> = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 4C3.11929 4 2 5.11929 2 6.5V15.5C2 16.8807 3.11929 18 4.5 18H9V19H8C7.44772 19 7 19.4477 7 20C7 20.5523 7.44772 21 8 21H16C16.5523 21 17 20.5523 17 20C17 19.4477 16.5523 19 16 19H15V18H19.5C20.8807 18 22 16.8807 22 15.5V6.5C22 5.11929 20.8807 4 19.5 4H4.5ZM19.5 6H4.5C4.22386 6 4 6.22386 4 6.5V15.5C4 15.7761 4.22386 16 4.5 16H19.5C19.7761 16 20 15.7761 20 15.5V6.5C20 6.22386 19.7761 6 19.5 6Z"
      fill="currentColor"
    />
  </svg>
);
