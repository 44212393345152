import React from "react";

import { IconProps } from "./types";

export const FileIcon: React.FC<IconProps> = ({ size = 32 }) => (
  <svg width={size} height={size} viewBox="0 0 32 32" fill="none">
    <path
      d="M10.6667 5.33325C9.19583 5.33325 8 6.52909 8 7.99992V23.9999C8 25.4708 9.19583 26.6666 10.6667 26.6666H21.3333C22.8042 26.6666 24 25.4708 24 23.9999V11.9999H18.6667C17.9292 11.9999 17.3333 11.4041 17.3333 10.6666V5.33325H10.6667ZM18.6667 5.33325V10.6666H24L18.6667 5.33325ZM17 14.9999V19.2541L18.2917 17.9624C18.6833 17.5708 19.3167 17.5708 19.7042 17.9624C20.0917 18.3541 20.0958 18.9874 19.7042 19.3749L16.7042 22.3749C16.3125 22.7666 15.6792 22.7666 15.2917 22.3749L12.2917 19.3749C11.9 18.9833 11.9 18.3499 12.2917 17.9624C12.6833 17.5749 13.3167 17.5708 13.7042 17.9624L14.9958 19.2541L15 14.9999C15 14.4458 15.4458 13.9999 16 13.9999C16.5542 13.9999 17 14.4458 17 14.9999Z"
      fill="currentColor"
    />
  </svg>
);
