import React from "react";

import { IconProps } from "./types";

export const LanguageIcon: React.FC<IconProps> = ({ size = 32 }) => (
  <svg width={size} height={size} viewBox="0 0 32 32" fill="none">
    <path
      d="M2.66675 10.6667C2.66675 9.19583 3.86258 8 5.33341 8H13.3334H15.3334H16.0001H26.6667C28.1376 8 29.3334 9.19583 29.3334 10.6667V21.3333C29.3334 22.8042 28.1376 24 26.6667 24H16.0001H15.3334H13.3334H5.33341C3.86258 24 2.66675 22.8042 2.66675 21.3333V10.6667ZM16.0001 10.6667V21.3333H26.6667V10.6667H16.0001ZM10.0959 12.6625C9.96258 12.3625 9.66258 12.1667 9.33341 12.1667C9.00425 12.1667 8.70425 12.3625 8.57091 12.6625L5.90425 18.6625C5.71675 19.0833 5.90841 19.575 6.32925 19.7625C6.75008 19.95 7.24175 19.7583 7.42925 19.3375L7.80008 18.5H10.8667L11.2376 19.3375C11.4251 19.7583 11.9167 19.9458 12.3376 19.7625C12.7584 19.5792 12.9459 19.0833 12.7626 18.6625L10.0959 12.6625ZM9.33341 15.05L10.1251 16.8333H8.54175L9.33341 15.05ZM21.3334 12.1667C21.7917 12.1667 22.1667 12.5417 22.1667 13V13.1667H24.0001H24.6667C25.1251 13.1667 25.5001 13.5417 25.5001 14C25.5001 14.4583 25.1251 14.8333 24.6667 14.8333H24.5834L24.5167 15.0208C24.1459 16.0375 23.5834 16.9625 22.8667 17.7458C22.9042 17.7708 22.9417 17.7917 22.9792 17.8125L23.7667 18.2833C24.1626 18.5208 24.2876 19.0333 24.0542 19.425C23.8209 19.8167 23.3042 19.9458 22.9126 19.7125L22.1251 19.2417C21.9376 19.1292 21.7584 19.0125 21.5792 18.8875C21.1376 19.2 20.6667 19.4708 20.1626 19.6958L20.0126 19.7625C19.5917 19.95 19.1001 19.7583 18.9126 19.3375C18.7251 18.9167 18.9167 18.425 19.3376 18.2375L19.4876 18.1708C19.7542 18.05 20.0126 17.9167 20.2584 17.7625L19.7501 17.2542C19.4251 16.9292 19.4251 16.4 19.7501 16.075C20.0751 15.75 20.6042 15.75 20.9292 16.075L21.5376 16.6833L21.5584 16.7042C22.0751 16.1583 22.4959 15.525 22.8001 14.8292H21.3334H18.3334C17.8751 14.8292 17.5001 14.4542 17.5001 13.9958C17.5001 13.5375 17.8751 13.1625 18.3334 13.1625H20.5001V12.9958C20.5001 12.5375 20.8751 12.1625 21.3334 12.1625V12.1667Z"
      fill="currentColor"
    />
  </svg>
);
