import { serverInstance as api } from "api/instances";
import { createUrlencoded } from "utils/createUrlencoded";
import { UserNotification } from "types";

export type UpdateUserData = {
  lang?: string;
  name?: string;
  surname?: string;
  email?: string;
  secret?: string;
  new_secret?: string;
  profile_picture?: string;
};

const URL = "user";

export const getUser = () => api.get(URL);

export const updateUser = (data: UpdateUserData) =>
  api.request({
    method: "patch",
    url: URL,
    data: createUrlencoded(data),
  });

export const getUserLanguages = () => api.get(`${URL}/languages`);

export const getUserNotification = () => api.get(`${URL}/notification`);

export const getUserGDPRExport = () => api.get(`${URL}/gdpr/export`);

export const getUserGDPRExportStatus = () =>
  api.get(`${URL}/gdpr/export/status`);

export const setUserNotification = (
  notificationId: UserNotification["id"],
  enabled: UserNotification["enabled"]
) => api.patch(`${URL}/notification/${notificationId}/${enabled}`);

export const getUserSession = () => api.get(`${URL}/session`);

export const destroyUserSession = (sessionId: string) =>
  api.delete(`${URL}/session/${sessionId}`);
