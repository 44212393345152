import axios from "axios";

import { URL, VERSION } from "constants/ENV";

const headers = {
  "x-app": `AccountDashboard/${VERSION}`,
  "x-device": navigator.userAgent.match(/\(([^)]+)\)/)?.[1] ?? "",
};

export const serverInstance = axios.create({
  baseURL: URL.API,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    ...headers,
  },
});

export const authInstance = axios.create({
  baseURL: URL.AUTH,
  headers,
});
