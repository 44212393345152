import React from "react";

interface CheckboxProps {
  isActive: boolean;
  onClick: () => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({ isActive, onClick }) => {
  return (
    <div
      className="w-[48px] h-[24px] rounded-full bg-gray4 cursor-pointer"
      onClick={onClick}
    >
      <div
        className={`bg-green rounded-full inline-block transition-all duration-100 ease-in-out ${
          isActive ? "pl-[24px]" : ""
        }`}
      >
        <div className="w-[24px] aspect-square rounded-full bg-white" />
      </div>
    </div>
  );
};
