import React from "react";

interface HeaderProps {
  title: string;
  children?: React.ReactNode;
}

export const Header: React.FC<HeaderProps> = ({ title, children }) => (
  <header className="flex flex-row px-8 py-6 md:px-6 md:py-4 sm:px-4 justify-between">
    <h1 className="text-white text-[34px] md:text-[32px] sm:text-[28px] font-bold">
      {title}
    </h1>

    <div>{children}</div>
  </header>
);
