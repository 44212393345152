import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "components/Modals/Modal";
import { ButtonGDPR } from "components/ButtonGDPR";

interface ModalDataExportProps {
  hideModal: () => void;
}

const data = [
  "We value your privacy and are committed to ensuring the transparency and control of your personal information. In accordance with the European Union's General Data Protection Regulation (GDPR), we empower you with the ability to access and export your personal data that we hold.",
  'By clicking on the "Request Export" button below, you can initiate the process to receive a copy of all your personal data stored by us. This includes any information that identifies you directly or indirectly, ensuring you have full insight into the data we have collected as part of our services.',
  "Please be aware that the process of gathering and compiling your personal data is thorough and may take up to one month to complete. We strive to ensure that your data is collected and transmitted securely, respecting your privacy rights at every step.",
  "Your personal data will be provided in a structured, commonly used, and machine-readable format, allowing you to easily review, store, or transfer your information as you see fit.",
  "If you have any questions or require further assistance regarding your personal data or any other privacy concerns, our support team is here to help.",
];

export const ModalDataExport: React.FC<ModalDataExportProps> = ({
  hideModal,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t("data_export")}
      footer={
        <div className="flex flex-1 justify-end">
          <ButtonGDPR />
        </div>
      }
      hideModal={hideModal}
    >
      <div className="max-w-[800px] px-8 sm:px-6 py-6 flex flex-col gap-6 sm:gap-4">
        {data.map((text, i) => (
          <span
            key={i}
            className="text-gray9 font-normal text-base sm:text-sm text-justify"
          >
            {text}
          </span>
        ))}
      </div>
    </Modal>
  );
};
