import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useAuthProvider } from "provider";
import { Header } from "components/Header";
import { Button } from "components/Button";
import {
  PersonalInfo,
  Preferences,
  Security,
  Privacy,
} from "components/Settings";
import { ExitIcon } from "icons/ExitIcon";

export const Profile: React.FC = () => {
  const { t } = useTranslation();

  const { user, logout } = useAuthProvider();

  const title = useCallback(
    (title: string) => (
      <span className="mt-8 mb-2 text-white text-xl font-bold">{title}</span>
    ),
    []
  );

  return (
    <>
      <Header title={t("profile")}>
        <Button type="danger" IconAfter={ExitIcon} onClick={logout}>
          {t("logout")}
        </Button>
      </Header>

      {user ? (
        <section className="!pt-0 p-8 md:p-6 sm:p-4 flex flex-col max-w-[800px]">
          {title(t("personal_info"))}
          <PersonalInfo />

          {title(t("security"))}
          <Security />

          {title(t("preferences"))}
          <Preferences />

          {title(t("privacy"))}
          <Privacy />
        </section>
      ) : null}
    </>
  );
};
