import React from "react";

import { IconProps } from "./types";

export const EyeSlashIcon: React.FC<IconProps> = ({ size = 32 }) => (
  <svg width={size} height={size} viewBox="0 0 32 32" fill="none">
    <path
      d="M4.28328 5.5458C3.84995 5.20414 3.22078 5.2833 2.87912 5.71664C2.53745 6.14997 2.61662 6.77914 3.04995 7.1208L27.7166 26.4541C28.15 26.7958 28.7791 26.7166 29.1208 26.2833C29.4625 25.85 29.3833 25.2208 28.9499 24.8791L24.5666 21.4458C26.2166 19.7541 27.3333 17.8583 27.8958 16.5125C28.0333 16.1833 28.0333 15.8166 27.8958 15.4875C27.275 14 25.9708 11.8333 24.0208 10.025C22.0625 8.19997 19.3666 6.66664 15.9999 6.66664C13.1583 6.66664 10.7916 7.76247 8.94578 9.19997L4.28328 5.5458ZM11.9625 11.5625C13.025 10.5916 14.4458 9.99997 15.9999 9.99997C19.3125 9.99997 22 12.6875 22 16C22 17.0375 21.7374 18.0125 21.275 18.8625L19.6666 17.6041C20.0166 16.8 20.1083 15.8791 19.8666 14.9666C19.4041 13.2375 17.875 12.075 16.1749 12.0041C15.9333 11.9958 15.7916 12.2583 15.8666 12.4916C15.9541 12.7583 16.0041 13.0416 16.0041 13.3375C16.0041 13.7625 15.9041 14.1625 15.7291 14.5166L11.9666 11.5666L11.9625 11.5625ZM18.2083 21.5791C17.525 21.85 16.7791 22 15.9999 22C12.6874 22 9.99995 19.3125 9.99995 16C9.99995 15.7125 10.0208 15.4333 10.0583 15.1583L6.12912 12.0625C5.17912 13.3 4.49995 14.5333 4.10412 15.4875C3.96662 15.8166 3.96662 16.1833 4.10412 16.5125C4.72495 18 6.02912 20.1666 7.97912 21.975C9.93745 23.8 12.6333 25.3333 15.9999 25.3333C17.9916 25.3333 19.7458 24.7958 21.2583 23.9791L18.2083 21.5791Z"
      fill="currentColor"
    />
  </svg>
);
