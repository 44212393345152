import React from "react";

import { IconProps } from "icons/types";
import { getCurrentScreenData } from "utils/getCurrentScreenData";

const styles = {
  primary: {
    color: "#000",
    backgroundColor: "#fff",
    borderColor: "#fff",
  },
  success: {
    backgroundColor: "#0C9",
    borderColor: "#0C9",
  },
  danger: {
    backgroundColor: "rgb(189,62,62,0.1)",
    borderColor: "#EB5757",
    color: "#EB5757",
  },
  transparent: {
    backgroundColor: "transparent",
  },
  transparentBorder: {
    marginLeft: 0,
    backgroundColor: "transparent",
    borderColor: "#4D4D4D",
  },
};

const iconSize = getCurrentScreenData({ sm: 20, md: 22, all: 24 });

interface ButtonProps {
  children?: React.ReactNode;
  IconBefore?: React.FC<IconProps>;
  IconAfter?: React.FC<IconProps>;
  small?: boolean;
  disabled?: boolean;
  type?: keyof typeof styles;
  onClick?: () => void;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  IconBefore,
  IconAfter,
  small,
  disabled,
  type = "primary",
  onClick,
}) => (
  <button
    className={`ml-3 rounded-lg hover:opacity-70 transition-all duration-300 ease-in-out flex items-center border border-transparent text-white disabled:opacity-50 ${
      small ? "h-[32px] px-4" : "h-[48px] md:h-[42px] px-6 md:px-4 sm:px-3"
    }`}
    style={styles[type]}
    type="button"
    disabled={disabled}
    onClick={onClick}
  >
    {IconBefore && <IconBefore size={small ? 16 : iconSize} />}

    {children ? (
      <span
        className={`font-semibold whitespace-nowrap text-base sm:text-sm
				${IconBefore ? "ml-3 sm:ml-2" : ""}
				${IconAfter ? "mr-3 sm:mr-2" : ""}`}
      >
        {children}
      </span>
    ) : null}

    {IconAfter && <IconAfter size={small ? 16 : iconSize} />}
  </button>
);
