import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { UpdateUserData } from "api/server";
import { useAuthProvider } from "provider";
import { getImageDimension } from "utils/getImageDimension";
import { convertImageToBase64 } from "utils/convertImageToBase64";
import { getProfilePicture } from "utils/getProfilePicture";
import { SER } from "utils/serverErrorHandler";
import { getCurrentScreenData } from "utils/getCurrentScreenData";
import { Button } from "components/Button";
import { Frame } from "components/Frame";
import { Input } from "components/Input";
import { ModalEditImage } from "components/Modals/ModalEditImage";
import { Image } from "components/Image";
import { Loading } from "components/Loading";
import { AccountIcon } from "icons/AccountIcon";
import { EditIcon } from "icons/EditIcon";

const imageSize = getCurrentScreenData({ sm: 76, md: 86, all: 96 });
const iconSize = getCurrentScreenData({ sm: 36, md: 42, all: 46 });

const maxImageSize = 400 * 1000;

type FormEventValues = {
  name: { value: string };
  surname: { value: string };
  email: { value: string };
  profile_picture: { files: File[] };
};

export const PersonalInfo: React.FC = () => {
  const [defaultBlobImage, setDefaultBlobImage] = useState("");
  const [blobImage, setBlobImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isModalEditImage, setIsModalEditImage] = useState(false);

  const { t } = useTranslation();

  const { user, setUser } = useAuthProvider();

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (user?.id) {
      fetch(getProfilePicture(user.id).user)
        .then((res) => (res.status === 200 ? res.blob() : null))
        .then((file) => {
          if (file) {
            setBlobImage(URL.createObjectURL(file));
            setDefaultBlobImage(blobImage);
          }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isEditMode]);

  const showModalEditImage = useCallback(() => {
    setIsModalEditImage(true);
  }, []);

  const hideModalEditImage = useCallback(
    (isResetBlobImage?: boolean) => {
      setIsModalEditImage(false);
      if (isResetBlobImage) setBlobImage(defaultBlobImage);
    },
    [defaultBlobImage]
  );

  const onSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();

      const target = event.target as typeof event.target & FormEventValues;

      const blob = await fetch(blobImage).then((res) => res.blob());
      const profile_picture = blobImage
        ? await convertImageToBase64(blob)
        : null;

      const name = target.name.value;
      const surname = target.surname.value;
      const email = target.email.value;

      if (user) {
        const data: UpdateUserData = {
          name,
          surname,
          email,
          ...(profile_picture ? { profile_picture } : {}),
        };

        SER(
          async () => {
            setIsLoading(true);

            await serverApi.updateUser(data);

            delete data.profile_picture;

            setUser({ ...user, ...data });
            setIsEditMode(false);
            toast.success(t("user_success_message"));
          },
          undefined,
          () => {
            setIsLoading(false);
          }
        );
      }
    },
    [t, user, blobImage, setUser]
  );

  const onChangeImage = useCallback(
    async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const file = target.files?.[0];

      if (file) {
        const { size } = await getImageDimension(file);

        if (size > maxImageSize) {
          toast.error(t("image_warning_message"));
        } else {
          setBlobImage(URL.createObjectURL(file));
          showModalEditImage();
        }

        target.value = "";
      }
    },
    [t, showModalEditImage]
  );

  const enableEditMode = useCallback(() => {
    setIsEditMode(true);
  }, []);

  const disableEditMode = useCallback(() => {
    setIsEditMode(false);
  }, []);

  const disabledFilds = useMemo(
    () => !(isEditMode && !isLoading),
    [isLoading, isEditMode]
  );

  return (
    <>
      <Frame
        title={t("basic_info")}
        HeaderIcon={AccountIcon}
        header={
          isEditMode ? (
            <div className="flex">
              <Button
                type="transparent"
                disabled={isLoading}
                onClick={disableEditMode}
              >
                {t("cancel")}
              </Button>
              <Button
                disabled={isLoading}
                IconBefore={isLoading ? Loading : undefined}
                onClick={() => formRef.current?.requestSubmit()}
              >
                {t("save")}
              </Button>
            </div>
          ) : (
            <Button
              type="transparentBorder"
              IconBefore={EditIcon}
              onClick={enableEditMode}
            >
              {t("edit")}
            </Button>
          )
        }
      >
        <form key={String(isEditMode)} ref={formRef} onSubmit={onSubmit}>
          <div className="py-6 sm:py-4 flex items-center border-b border-gray3">
            <span className="text-white text-base sm:text-sm font-semibold">
              {t("profile_picture")}
            </span>

            <div className="flex flex-1 justify-end items-center">
              <Image
                imageSize={imageSize}
                iconSize={iconSize}
                src={blobImage}
              />

              {!disabledFilds ? (
                <>
                  <label
                    htmlFor="profile_picture"
                    className="ml-6 sm:ml-4 cursor-pointer"
                  >
                    <div className="pointer-events-none">
                      <Button type="transparentBorder">{t("change")}</Button>
                    </div>
                  </label>

                  <input
                    id="profile_picture"
                    name="profile_picture"
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={onChangeImage}
                    className="hidden"
                  />
                </>
              ) : null}
            </div>
          </div>

          <div className="pt-2 pb-6">
            <Input
              name="name"
              label={t("name")}
              defaultValue={user?.name}
              disabled={disabledFilds}
            />
            <Input
              name="surname"
              label={t("surname")}
              defaultValue={user?.surname}
              disabled={disabledFilds}
            />
            <Input
              name="email"
              type="email"
              label={t("email")}
              defaultValue={user?.email}
              disabled={disabledFilds}
            />
          </div>
        </form>
      </Frame>

      {isModalEditImage ? (
        <ModalEditImage
          blobImage={blobImage}
          setBlobImage={setBlobImage}
          hideModal={hideModalEditImage}
        />
      ) : null}
    </>
  );
};
