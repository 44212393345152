import React from "react";

import { IconProps } from "./types";

export const AccountIcon: React.FC<IconProps> = ({ size = 32 }) => (
  <svg width={size} height={size} viewBox="0 0 32 32" fill="none">
    <path
      d="M16.0001 16C17.4146 16 18.7711 15.4381 19.7713 14.4379C20.7715 13.4377 21.3334 12.0811 21.3334 10.6666C21.3334 9.25216 20.7715 7.8956 19.7713 6.89541C18.7711 5.89522 17.4146 5.33331 16.0001 5.33331C14.5856 5.33331 13.229 5.89522 12.2288 6.89541C11.2287 7.8956 10.6667 9.25216 10.6667 10.6666C10.6667 12.0811 11.2287 13.4377 12.2288 14.4379C13.229 15.4381 14.5856 16 16.0001 16ZM14.0959 18C9.99175 18 6.66675 21.325 6.66675 25.4291C6.66675 26.1125 7.22091 26.6666 7.90425 26.6666H24.0959C24.7792 26.6666 25.3334 26.1125 25.3334 25.4291C25.3334 21.325 22.0084 18 17.9042 18H14.0959Z"
      fill="currentColor"
    />
  </svg>
);
