import React from "react";

import { IconProps } from "./types";

export const UserIcon: React.FC<IconProps> = ({ size = 32 }) => (
  <svg width={size} height={size} viewBox="0 0 32 32" fill="none">
    <path
      d="M16.0001 16C17.4146 16 18.7711 15.4381 19.7713 14.4379C20.7715 13.4377 21.3334 12.0812 21.3334 10.6667C21.3334 9.25222 20.7715 7.89567 19.7713 6.89547C18.7711 5.89528 17.4146 5.33337 16.0001 5.33337C14.5856 5.33337 13.229 5.89528 12.2288 6.89547C11.2287 7.89567 10.6667 9.25222 10.6667 10.6667C10.6667 12.0812 11.2287 13.4377 12.2288 14.4379C13.229 15.4381 14.5856 16 16.0001 16ZM14.0959 18C9.99175 18 6.66675 21.325 6.66675 25.4292C6.66675 26.1125 7.22091 26.6667 7.90425 26.6667H24.0959C24.7792 26.6667 25.3334 26.1125 25.3334 25.4292C25.3334 21.325 22.0084 18 17.9042 18H14.0959Z"
      fill="currentColor"
    />
  </svg>
);
