import React from "react";

import { LoadingIcon } from "icons/LoadingIcon";
import { IconProps } from "icons/types";

export const Loading: React.FC<IconProps> = ({ size }) => (
  <div className="flex flex-1 items-center justify-center">
    <div className="animate-spin">
      <LoadingIcon size={size} />
    </div>
  </div>
);
