import React from "react";

import { IconProps } from "./types";

export const HomeIcon: React.FC<IconProps> = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 18.3944V10C19 9.66565 18.8329 9.35342 18.5547 9.16795L12.5547 5.16795C12.2188 4.94402 11.7812 4.94402 11.4453 5.16795L5.4453 9.16795C5.1671 9.35342 5 9.66565 5 10V18.3944H8V15.8944C8 14.2376 9.34315 12.8944 11 12.8944H13C14.6569 12.8944 16 14.2376 16 15.8944V18.3944H19ZM4.3359 7.50385C3.5013 8.06025 3 8.99694 3 10V18.8944C3 19.7229 3.67157 20.3944 4.5 20.3944H8.5C9.32843 20.3944 10 19.7229 10 18.8944V15.8944C10 15.3422 10.4477 14.8944 11 14.8944H13C13.5523 14.8944 14 15.3422 14 15.8944V18.8944C14 19.7229 14.6716 20.3944 15.5 20.3944H19.5C20.3284 20.3944 21 19.7229 21 18.8944V10C21 8.99694 20.4987 8.06025 19.6641 7.50385L13.6641 3.50385C12.6564 2.83205 11.3436 2.83205 10.3359 3.50385L4.3359 7.50385Z"
      fill="currentColor"
    />
  </svg>
);
