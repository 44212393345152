import React from "react";

import { IconProps } from "./types";

export const MegaphoneIcon: React.FC<IconProps> = ({ size = 32 }) => (
  <svg width={size} height={size} viewBox="0 0 32 32" fill="none">
    <path
      d="M25.3333 6.66671C25.3333 6.12921 25.0083 5.64171 24.5083 5.43337C24.0083 5.22504 23.4374 5.34171 23.0541 5.72087L21.2374 7.54171C19.2374 9.54171 16.5249 10.6667 13.6958 10.6667H13.3333H11.9999H7.99992C6.52909 10.6667 5.33325 11.8625 5.33325 13.3334V17.3334C5.33325 18.8042 6.52909 20 7.99992 20V25.3334C7.99992 26.0709 8.59575 26.6667 9.33325 26.6667H11.9999C12.7374 26.6667 13.3333 26.0709 13.3333 25.3334V20H13.6958C16.5249 20 19.2374 21.125 21.2374 23.125L23.0541 24.9417C23.4374 25.325 24.0083 25.4375 24.5083 25.2292C25.0083 25.0209 25.3333 24.5375 25.3333 23.9959V17.85C26.1083 17.4834 26.6666 16.4959 26.6666 15.3334C26.6666 14.1709 26.1083 13.1834 25.3333 12.8167V6.66671ZM22.6666 9.86254V15.3334V20.8042C20.2166 18.575 17.0208 17.3334 13.6958 17.3334H13.3333V13.3334H13.6958C17.0208 13.3334 20.2166 12.0917 22.6666 9.86254Z"
      fill="currentColor"
    />
  </svg>
);
