import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useAuthProvider } from "provider";
import { Header } from "components/Header";
import { Frame } from "components/Frame";

export const Home: React.FC = () => {
  const { t } = useTranslation();

  const { user } = useAuthProvider();

  const data = useMemo(
    () => [
      { name: t("cloud"), color: "#00714F", url: "https://zirkonzahn.cloud" },
      { name: t("help"), color: "#EAC051", url: "https://help.zirkonzahn.com" },
    ],
    [t]
  );

  return (
    <>
      <Header title={t("home")} />

      <section className="px-8 py-6 md:px-6 md:py-4 sm:px-4">
        <div className="mb-[64px] sm:mb-[48px] max-w-[540px]">
          <h2 className="text-white text-2xl font-bold">
            {t("welcome_back")} {user?.name} {user?.surname}!
          </h2>
          <span className="block mt-4 text-gray9 text-base font-normal">
            {t("welcome_text")}
          </span>
        </div>

        <Frame title={t("apps")}>
          <div className="py-8 md:py-6 sm:py-4 flex gap-10">
            {data.map((item) => (
              <div
                key={item.name}
                className="flex flex-col items-center cursor-pointer"
                onClick={() => window.open(item.url, "_blank")}
              >
                <div
                  className="w-[64px] sm:w-[56px] aspect-square rounded-lg flex justify-center items-center"
                  style={{ backgroundColor: item.color }}
                >
                  <span className="text-[48px] sm:text-[38px] font-thin text-white leading-6">
                    {item.name[0]}
                  </span>
                </div>

                <span className="mt-2 text-white font-normal text-sm">
                  {item.name}
                </span>
              </div>
            ))}
          </div>
        </Frame>
      </section>
    </>
  );
};
