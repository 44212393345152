import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { useAuthProvider } from "provider";
import { Modal } from "components/Modals/Modal";
import { Button } from "components/Button";
import { SER } from "utils/serverErrorHandler";
import { UserLanguage } from "types";

interface ModalChangeLanguageProps {
  userLanguages: UserLanguage[];
  hideModal: () => void;
}

export const ModalChangeLanguage: React.FC<ModalChangeLanguageProps> = ({
  userLanguages,
  hideModal,
}) => {
  const { t } = useTranslation();

  const { user, setUser } = useAuthProvider();

  const [selectedLanguage, setSelectedLanguage] = useState(user?.lang);

  const onChangeLanguage = useCallback(() => {
    if (user && selectedLanguage) {
      SER(async () => {
        const data = { lang: selectedLanguage };
        await serverApi.updateUser(data);

        setUser({ ...user, ...data });
        toast.success(t("language_success_message"));
        hideModal();
      });
    }
  }, [user, selectedLanguage, t, setUser, hideModal]);

  return (
    <Modal
      title={t("change_language")}
      footer={
        <div className="flex flex-1 justify-end">
          <Button type="transparent" onClick={hideModal}>
            {t("cancel")}
          </Button>
          <Button onClick={onChangeLanguage}>{t("change")}</Button>
        </div>
      }
    >
      <div className="px-8 py-6 sm:px-6 sm:py-4">
        {userLanguages.map((language) => {
          const isActive = language.lang === selectedLanguage;

          return (
            <div
              key={language.lang}
              className="h-[64px] sm:h-[56px] flex items-center cursor-pointer"
              onClick={() => setSelectedLanguage(language.lang)}
            >
              <div
                className={`w-[24px] aspect-square rounded-full border-2 flex items-center justify-center ${
                  isActive ? "border-green" : "border-gray4"
                }`}
              >
                {isActive ? (
                  <div className="w-[16px] aspect-square bg-green rounded-full" />
                ) : null}
              </div>
              <span className="ml-4 text-white text-base sm:text-sm font-normal">
                {language.name}
              </span>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};
